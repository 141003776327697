import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerTeam from '../components/BannerTeam'

import aarav from '../assets/images/team/aarav.jpeg'
import aman from '../assets/images/team/aman.jpg'
// import dhriti from '../assets/images/team/dhriti.jpg'
import muthu from '../assets/images/team/muthu.jpeg'
import rahul from '../assets/images/team/rahul.jpg'
import varun from '../assets/images/team/varun.jpg'
// import rohan from '../assets/images/team/rohan.png'
// import renee from '../assets/images/team/renee.jpg'
import moni from '../assets/images/team/moni.jpg'
import keerthi from '../assets/images/team/keerthi.jpeg'
import thripura from '../assets/images/team/thripura.jpeg'
import daiwik from '../assets/images/team/daiwik.jpg'
import anisha from '../assets/images/team/anisha.jpeg'
import shivaani from '../assets/images/team/shivaani.jpeg'
import kaushik from '../assets/images/team/kaushik.jpg'
import harsha from '../assets/images/team/harsha.jpg'
import sid from '../assets/images/team/sid.jpeg'
import aum from '../assets/images/team/aum.jpg'
import sloka from '../assets/images/team/sloka.png'
import anishaJr from '../assets/images/team/anishaJr.jpg'

const Team = () => (
  <Layout>
    <Helmet>
      <title>Krypton Warriors</title>
      <meta name="description" content="Landing Page" />
      <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "53ca0e93ef80463192e735fa104fef39"}'></script>n
    </Helmet>

    <BannerTeam />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>About</h2>
          </header>
          <p>
            We are the Krypton Warriors team from Parsippany, New Jersey. Our
            team consists of 14 members. This is our fourth year of participating
            in the <em>FIRST</em> Tech Challenge (FTC) competition. We participated in
            FLL for the three years before that. We all came together as a team because
            of our love for science, technology, engineering and Math. 
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <Link className="image">
            <img src={rahul} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Rahul Ramakrishnan</h3>
              </header>
              <p>
                Hi, my name is Rahul and I am in 12th Grade at Morris Hills High
                School. I participated in FLL for 3 years before joining FTC.
                This is my fourth year in FTC and I am one of our team's
                programmers. I love to program in Java and CAD in Solidworks.
                Outside of FTC, I like to play the piano and read books.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img src={aarav} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Aarav Mulinti</h3>
              </header>
              <p>
                Hi, my name is Aarav and I am in 12th Grade at Montville Township
                High School. I participated in FLL for 3 years before joining
                FTC. This is my fourth year in FTC and I am one of our team's
                builders and designers. I love to build and design using CAD in
                Solidworks. Outside of FTC, I like to play basketball and tennis
                and read books.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img src={sloka} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Sloka Annapareddy</h3>
              </header>
              <p>
              Hello, my name is Sloka Annapareddy and I am in 7th grade in 
              Central Middle School, Parsippany, New Jersey. This is my 
              first year in FTC and I have done two years of FLL before 
              joining Krypton Warriors. I am a programmer on the team and 
              also help out with outreaches and connects. Outside of robotics 
              I enjoy playing basketball, going roller skating, and playing the violin.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img src={anishaJr} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Anisha Senthilkumaran</h3>
              </header>
              <p>
              Hello, my name is Anisha Senthilkumaran and I am in 6th grade at Randolph Middle School. 
              This is my first year in FTC and the Krpton Warriors team, but prior to that, i have done junior FLL. 
              I am mainly a CADer and work in 3D printing on this team. I have learned lots of skills participating 
              that I can use in my everyday life. Outside of the Krypton Warriors extra curricular things I like to 
              do are, playing violin, piano, tennis, building things, and classical dance.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
            src={muthu}
            alt=""
            style={{ width: 'auto', height: '20em', marginLeft: '10%' }}
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Muthu Veerappan</h3>
              </header>
              <p>
                Hello everyone, my name is Muthu Veerappan and I am in 12th Grade
                at Morris Hills High School. I participated in a single year of
                FLL with the Krypton Warriors before going into FTC. This will
                be my fourth year being part of FTC. I love to design new things in CAD and
                contribute to all areas of the team. Outside of FTC, I
                love to play a lot of sports, mainly soccer, read books, and
                just learn new things.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={aum}
              style={{ width: 'auto', height: '20em', marginLeft: '10%' }}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Aum Shah</h3>
              </header>
              <p>
              Hi, my name is Aum Shah and I am in  10th grade at the Academy for Mathematics, 
              Science and Engineering. This is my first year in FTC with the Krypton Warriors. 
              I am one of the programmers on the team and enjoy math and engineering. Outside of
              robotics, I love to play the piano, tennis, and love to read.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={thripura}
              style={{ width: 'auto', height: '20em', marginLeft: '10%' }}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Thripura Arunraj</h3>
              </header>
              <p>
              Hi, my name is Thripura Arunraj and I am in 8th grade at Bridgewater
              Raritan Middle School. This is my first year in FTC and with the Krypton
              Warriors. I am a programmer on the team, and I also work on outreaches
              and connects. Besides robotics, some of my hobbies are painting,
              playing viola, and playing tennis.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={daiwik}
              style={{ width: 'auto', height: '20em', marginLeft: '10%' }}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Daiwik Namala</h3>
              </header>
              <p>
              Hi, my name is Daiwik Namala and I am in 9th grade at Parsippany
              Hills High School. This is my first year in FTC and with the Krypton
              Warriors. I am a driver and builder on the team, and I also work on outreaches
              and connects. Besides robotics, some of my hobbies are playing video games,
              cook, programming PCs and listening to music on Spotify.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={anisha}
              style={{ width: 'auto', height: '20em', marginLeft: '10%' }}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Anisha Mulinti</h3>
              </header>
              <p>
              Hi! My name is Anisha Mulinti and I am in 10th grade at Montville Township High School. This is my 7th year with the FIRST organization as I have previously competed in Jr FLL, FLL, and now FTC. I am also a part of the NJ STEM Girls. I am a coder and I have learned a lot of skills and lessons throughout robotics and use it in my everyday life. Outside of FTC, I enjoy playing tennis and going to the beach.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={shivaani}
              style={{ width: 'auto', height: '20em', marginLeft: '10%' }}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Shivaani Sangar</h3>
              </header>
              <p>
              Hello, My name is Shivaani Sangar, and I am a 8th-grade student at Brooklawn Middle School. As a passionate learner, I am excited to embark on a new adventure and participate in the world of robotics. This is my first time exploring this field, and I am thrilled to be part of the FTC team as a rookie. I bring my unique set of skills to the team as one of its programmers, but I also like to get involved in connects and outreach activities. Aside from robotics, I also enjoy participating in cross country and dance. In my free time, I love spending time with my friends, sharing my love of cooking, and cultivating my garden. Overall, I'm grateful for all the opportunities that come my way and look forward to continuing to pursue my passions.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={kaushik}
              style={{ width: 'auto', height: '20em', marginLeft: '10%' }}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Kaushik Panyala</h3>
              </header>
              <p>
              Hi, my name is Kaushik Panyala and I am in 9th grade at Morris Hills School. This is my first year in FTC and with Krypton Warriors and prior to FTC, I also participated in FLL as well. I love coding and I am the programmer on the team. I also help with organizing outreaches and connects. Besides robotics, I love to play guitar, tennis and chess.  My hobbies include, playing video games, reading books, and listening to music.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={harsha}
              style={{ width: 'auto', height: '20em', marginLeft: '10%' }}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Harsha Tumu</h3>
              </header>
              <p>
              Hi, my name is Harsha Tumu and I am in 10th grade at Morris Hills High School, Academy of Math Science and Engineering. This is my first year in FTC and with the Krypton Warriors. I am a builder on the team, and I am a driver. Programming also interests me. Besides robotics, some of my hobbies are playing video games, taking apart things, and cooking.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={sid}
              style={{ width: 'auto', height: '20em', marginLeft: '10%' }}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Sid Tumu</h3>
              </header>
              <p>
              Hi, my name is Sid Tumu and I am in 8th grade at Brooklawn Middle School. This is my first year in FTC and with the Krypton Warriors. I am a builder on the team and a human player. Besides robotics, some of my hobbies are playing racketball, video editing, and listening to music.
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Team
